import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Planner from './Planner';
import Dashboard from './Dashboard';
import { reducer as formReducer } from 'redux-form'

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  planner : Planner,
  form: formReducer,
  dashboard:Dashboard
});
