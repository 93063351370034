import * as ActionTypes from 'constants/ActionTypes';


export function fetchListaCities(data) {
  return {
    type: ActionTypes.FECTH_CITIES_REQUEST,
    payload: data
  }
}

export function fetchListaCitiesSuccess(data) {
  return {
    type: ActionTypes.FECTH_CITIES_SUCCESS,
    payload: data
  }
}

export function fetchListaCitiesFailure(error) {
  return {
    type: ActionTypes.FECTH_CITIES_FAILURE,
    payload: JSON.parse(error.message)
  }
}

export function fetchListaTalleres(payload) {
  return {
    type: ActionTypes.FETCH_LISTA_TALLERES_REQUEST,
    payload: payload
  }
}

export function fetchListaTalleresSuccess(data) {
  return {
    type: ActionTypes.FETCH_LISTA_TALLERES_SUCCESS,
    payload: data
  }
}

export function fetchListaTalleresFailure(error) {
  return {
    type: ActionTypes.FETCH_LISTA_TALLERES_FAILURE,
    payload: JSON.parse(error.message)
  }
}


export function fetchDashboard(payload) {
  return {
    type: ActionTypes.FETCH_DASHBOARD_REQUEST,
    payload: payload
  }
}

export function fetchDashboardSuccess(data) {
  return {
    type: ActionTypes.FETCH_DASHBOARD_SUCCESS,
    payload: data
  }
}

export function fetchDashboardFailure(error) {
  return {
    type: ActionTypes.FETCH_DASHBOARD_FAILURE,
    payload: JSON.parse(error.message)
  }
}

export function fetchDashboardDetails(payload) {
  return {
    type: ActionTypes.FETCH_DASHBOARD_DETAILS_REQUEST,
    payload: payload
  }
}

export function fetchDashboardDetailsSuccess(data) {
  return {
    type: ActionTypes.FETCH_DASHBOARD_DETAILS_SUCCESS,
    payload: data
  }
}

export function fetchDashboardDetailsFailure(error) {
  return {
    type: ActionTypes.FETCH_DASHBOARD_DETAILS_FAILURE,
    payload: JSON.parse(error.message)
  }
}

export function fetchExportCasosTaller(payload) {
  return {
    type: ActionTypes.FETCH_EXPORT_CASOS_REQUEST,
    payload: payload
  }
}

export function fetchExportCasosTallerSuccess(data) {
  return {
    type: ActionTypes.FETCH_EXPORT_CASOS_SUCCESS,
    payload: data
  }
}

export function fetchExportCasosTallerFailure(error) {
  return {
    type: ActionTypes.FETCH_EXPORT_CASOS_FAILURE,
    payload: JSON.parse(error.message)
  }
}

export function fetchListaFilter(payload) {
  return {
    type: ActionTypes.FETCH_LISTA_FILTER_REQUEST,
    payload: payload
  }
}

export function fetchListaFilterSuccess(data) {
  return {
    type: ActionTypes.FETCH_LISTA_FILTER_SUCCESS,
    payload: data
  }
}

export function fetchListaFilterFailure(error) {
  return {
    type: ActionTypes.FETCH_LISTA_FILTER_FAILURE,
    payload: JSON.parse(error.message)
  }
}