import {all} from 'redux-saga/effects';
import authSagas from './Auth';
import plannerSagas from './Planner';
import dashboardSagas from './Dashboard';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),plannerSagas(),dashboardSagas()
    ]);
}
