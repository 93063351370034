import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
    fetchCasosSuccess,
    fetchCasosFailure,
    fetchListaEventosSuccess,
    fetchListaEventosFailure,
    fetchListaMarcasSuccess,
    fetchListaMarcasFailure,
    fetchListaCompaniasSuccess,
    fetchListaCompaniasFailure,
    addEventSuccess,
    addEventFailure,
    fetchEventsSuccess,
    fetchEventsFailure,
} from 'actions/Planeador';
import {
    FETCH_LISTA_CASOS_REQUEST,
    FETCH_LISTA_EVENTOS_REQUEST,
    FETCH_LISTA_MARCAS_REQUEST,
    FETCH_LISTA_COMPANIAS_REQUEST,
    ADD_EVENT_REQUEST,
    FECTH_EVENT_REQUEST
} from 'constants/ActionTypes';
import { casos, listaEventos, listaMarcas, listaCompanias, addEvent, eventos } from '../services/tallerService';

const fetchCasosRequest = async (payload) =>
    await casos(payload)
        .then(response => response);

function* fetchCasos({ payload }) {
    try {
        const fetchData = yield call(fetchCasosRequest, payload);
        yield put(fetchCasosSuccess(fetchData));
    } catch (error) {
        yield put(fetchCasosFailure(error));
    }
}

const addEventRequest = async (payload) =>
    await addEvent(payload)
        .then(response => response);

function* addEventFunction({ payload }) {
    try {
        yield call(addEventRequest, payload);
        yield put(addEventSuccess());
    } catch (error) {
        yield put(addEventFailure(error));
    }
}

const fetchListaEventosRequest = async () =>
    await listaEventos()
        .then(response => response);

function* fetchListaEventos() {
    try {
        const fetchData = yield call(fetchListaEventosRequest);
        yield put(fetchListaEventosSuccess(fetchData));
    } catch (error) {
        yield put(fetchListaEventosFailure(error));
    }
}

const fetchListaMarcasRequest = async () =>
    await listaMarcas()
        .then(response => response);

function* fetchListaMarcas() {
    try {
        const fetchData = yield call(fetchListaMarcasRequest);
        yield put(fetchListaMarcasSuccess(fetchData));
    } catch (error) {
        yield put(fetchListaMarcasFailure(error));
    }
}

const fetchListaCompaniasRequest = async () =>
    await listaCompanias()
        .then(response => response);

function* fetchListaCompanias() {
    try {
        const fetchData = yield call(fetchListaCompaniasRequest);
        yield put(fetchListaCompaniasSuccess(fetchData));
    } catch (error) {
        yield put(fetchListaCompaniasFailure(error));
    }
}

const fetchEventsRequest = async (payload) =>
    await eventos(payload)
        .then(response => response);

function* fetchEvents({ payload }) {
    try {
        const fetchData = yield call(fetchEventsRequest, payload);
        yield put(fetchEventsSuccess(fetchData));
    } catch (error) {
        yield put(fetchEventsFailure(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(FETCH_LISTA_CASOS_REQUEST, fetchCasos),
        takeEvery(FETCH_LISTA_EVENTOS_REQUEST, fetchListaEventos),
        takeEvery(FETCH_LISTA_MARCAS_REQUEST, fetchListaMarcas),
        takeEvery(FETCH_LISTA_COMPANIAS_REQUEST, fetchListaCompanias),
        takeEvery(ADD_EVENT_REQUEST, addEventFunction),
        takeEvery(FECTH_EVENT_REQUEST, fetchEvents)
    ]);
}