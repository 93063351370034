let resStatus = 0
let statusText = "";
export const listaCiudades = (request) => {
  console.log(request);
  const EVENTOS_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Dashboard/listaCiudades?Region="+request.region;
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    }
  };

  return fetch(EVENTOS_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};

export const listaTalleres = (request) => {
  const TALLERES_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Dashboard/listaTalleres?IdCiudad=" + request.ciudad;
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    }
  };

  return fetch(TALLERES_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};

export const getDashboard = (request) => {
  const DASHBOARD_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Dashboard/listaTableros";
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    },
    body: JSON.stringify(request)
  };

  return fetch(DASHBOARD_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      } else {
        return res.text()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText + res }));
      }
    })
};


export const getDashboardDetail = (request) => {
  const DASHBOARD_DETAILS_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Dashboard/listaCasosTablero";
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    },
    body: JSON.stringify(request)
  };

  return fetch(DASHBOARD_DETAILS_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};

export const exportarCasosTablero = (request) => {
  const EVENTOS_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/dashboard/ExportarCasosTablero";
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    },
    body: JSON.stringify(request)
  };

  return fetch(EVENTOS_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.text()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};
export const consultarListaFiltro = (request) => {
  const EVENTOS_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/dashboard/listaFiltros?TipoFiltro=" + request.tipo;
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    }
  };

  return fetch(EVENTOS_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return {tipo:request.tipo,list:res};
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};


