import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import configureStore, { history } from './store';
import './firebase/firebase';
import App from './containers/App';
import { DragDropContextProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend';
import {isMobile} from 'react-device-detect';

export const store = configureStore();

const MainApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <DragDropContextProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/`} component={App} />
        </Switch>
      </DragDropContextProvider>
    </ConnectedRouter>
  </Provider>;


export default MainApp;