import {
     FETCH_LISTA_EVENTOS_REQUEST,
     FETCH_LISTA_EVENTOS_SUCCESS,
     FETCH_LISTA_EVENTOS_FAILURE,
     FETCH_LISTA_CASOS_REQUEST,
     FETCH_LISTA_CASOS_SUCCESS,
     FETCH_LISTA_CASOS_FAILURE,
     FETCH_LISTA_MARCAS_REQUEST,
     FETCH_LISTA_MARCAS_SUCCESS,
     FETCH_LISTA_MARCAS_FAILURE,
     FETCH_LISTA_COMPANIAS_REQUEST,
     FETCH_LISTA_COMPANIAS_SUCCESS,
     FETCH_LISTA_COMPANIAS_FAILURE,
     ADD_EVENT_REQUEST,
     ADD_EVENT_SUCCESS,
     ADD_EVENT_FAILURE,
     FECTH_EVENT_REQUEST,
     FECTH_EVENT_SUCCESS,
     FECTH_EVENT_FAILURE
} from 'constants/ActionTypes';

export function fetchCasos(payload) {
  return {
    type: FETCH_LISTA_CASOS_REQUEST,
    payload: payload
  }
}

export function fetchCasosSuccess(data) {
  return {
    type: FETCH_LISTA_CASOS_SUCCESS,
    payload: data
  }
}

export function fetchCasosFailure(error) {    
  return {
    type: FETCH_LISTA_CASOS_FAILURE,
    payload: JSON.parse(error.message)
  }
}
export function addEvent(payload) {
  return {
    type: ADD_EVENT_REQUEST,
    payload: payload
  }
}

export function addEventSuccess() {
  return {
    type: ADD_EVENT_SUCCESS,
    payload: {}
  }
}

export function addEventFailure(error) {      
  return {
    type: ADD_EVENT_FAILURE,
    payload: JSON.parse(error.message)
  }
}

export function fetchListaEventos() {
  return {
    type: FETCH_LISTA_EVENTOS_REQUEST
  }
}

export function fetchListaEventosSuccess(data) {
  return {
    type: FETCH_LISTA_EVENTOS_SUCCESS,
    payload: data
  }
}

export function fetchListaEventosFailure(error) {    
  return {
    type: FETCH_LISTA_EVENTOS_FAILURE,
    payload: JSON.parse(error.message)
  }
}

export function fetchListaMarcas() {
  return {
    type: FETCH_LISTA_MARCAS_REQUEST
  }
}

export function fetchListaMarcasSuccess(data) {
  return {
    type: FETCH_LISTA_MARCAS_SUCCESS,
    payload: data
  }
}

export function fetchListaMarcasFailure(error) {    
  return {
    type: FETCH_LISTA_MARCAS_FAILURE,
    payload: JSON.parse(error.message)
  }
}

export function fetchListaCompanias() {
  return {
    type: FETCH_LISTA_COMPANIAS_REQUEST
  }
}

export function fetchListaCompaniasSuccess(data) {
  return {
    type: FETCH_LISTA_COMPANIAS_SUCCESS,
    payload: data
  }
}

export function fetchListaCompaniasFailure(error) {    
  return {
    type: FETCH_LISTA_COMPANIAS_FAILURE,
    payload: JSON.parse(error.message)
  }
}


export function fetchEvents(payload) {
  return {
    type: FECTH_EVENT_REQUEST,
    payload: payload
  }
}

export function fetchEventsSuccess(data) {
  return {
    type: FECTH_EVENT_SUCCESS,
    payload: data
  }
}

export function fetchEventsFailure(error) {    
  return {
    type: FECTH_EVENT_FAILURE,
    payload: JSON.parse(error.message)
  }
}