import * as ACTIONS from 'constants/ActionTypes';

export const INIT_STATE = {
    loader: true,
    loaderTableros: true,
    isError: false,
    errorMessage: "",
    cities: [],
    talleres: [],
    dashboardData: {},
    dashboardDetails: {},
    peritos: [],
    amparos: [],
    estadosperitacion: [],
    regiones: [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTIONS.FECTH_CITIES_SUCCESS: {
            return {
                ...state,
                loader: false,
                cities: action.payload
            }
        }
        case ACTIONS.FETCH_LISTA_TALLERES_SUCCESS: {
            return {
                ...state,
                loader: false,
                talleres: action.payload
            }
        }
        case ACTIONS.FETCH_DASHBOARD_SUCCESS: {
            return {
                ...state,
                loader: false,
                loaderTableros: false,
                dashboardData: action.payload
            }
        }
        case ACTIONS.FETCH_DASHBOARD_FAILURE: {
            return {
                ...state,
                loader: false,
                loaderTableros: false,
                isError: true,
                dashboardData: {},
                errorMessage: JSON.stringify(action.payload)
            }
        }
        case ACTIONS.FETCH_DASHBOARD_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                dashboardDetails: action.payload
            }
        }
        case ACTIONS.FETCH_DASHBOARD_REQUEST: {
            return {
                ...state,
                loader: false,
                loaderTableros: true,
                dashboardData: null
            }
        }
        case ACTIONS.FETCH_LISTA_FILTER_SUCCESS: {
            switch (action.payload.tipo) {
                case "perito":
                    return {
                        ...state,
                        loader: false,
                        peritos: action.payload.list
                    }
                case "amparo":
                    return {
                        ...state,
                        loader: false,
                        amparos: action.payload.list
                    }
                case "estadoperitacion":
                    return {
                        ...state,
                        loader: false,
                        estadosperitacion: action.payload.list
                    }
                case "regiones":
                    return {
                        ...state,
                        loader: false,
                        regiones: action.payload.list
                    }
                default:
                    return {
                        ...state
                    }
            }

        }

        default:
            return {
                ...state,
                cities: [],
                talleres: []
            }
    }
}