let resStatus = 0
let statusText = "";
export const casos = (request) => {
  const CASOS_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Planner/casosTaller";
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    },
    body: JSON.stringify(request)
  };

  return fetch(CASOS_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};

export const addEvent = (request) => {
  const ADD_EVENT_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Taller/NuevoEvento";
  const authData = JSON.parse(localStorage.getItem('user_id'));
  var formData = new FormData();

  for (var name in request) {
    if (name === "files" && request[name].length > 0) {
      for (let i = 0; i < request[name].length; i++) {
        formData.append(name, request[name][i]);
      }
    } else {
      formData.append(name, request[name]);
    }

  }

  const parameters = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + authData.token
    },
    body: formData
  };

  return fetch(ADD_EVENT_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        case 204:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};

export const listaEventos = () => {
  const EVENTOS_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Planner/listaEventosPlanner";
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    }
  };

  return fetch(EVENTOS_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};

export const listaMarcas = () => {
  const MARCAS_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Planner/listaMarcas";
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    }
  };

  return fetch(MARCAS_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};

export const listaCompanias = () => {
  const COMPANIAS_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Planner/listaCompanias";
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    }
  };

  return fetch(COMPANIAS_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};


export const eventos = (request) => {
  const EVENTOS_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Asegurado/eventos?placa=" + request.placa;
  const authData = JSON.parse(localStorage.getItem('user_id'));
  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authData.token
    },
    body: JSON.stringify(request)
  };

  return fetch(EVENTOS_API_ENDPOINT, parameters)
    .then(res => {
      resStatus = res.status;
      statusText = res.statusText;
      if (resStatus === 200) {
        return res.json()
      }
    })
    .then(res => {
      switch (resStatus) {
        case 200:
          return res;
        default:
          throw new Error(JSON.stringify({ code: resStatus, statusMessage: statusText }));
      }
    })
};
