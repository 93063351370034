import { all, call, put, takeEvery } from 'redux-saga/effects';
import * as ACTIONS from 'actions/Dashboard';
import * as ACTION_TYPES from 'constants/ActionTypes';
import * as service from '../services/dashboard';

const fetchListaCitiesRequest = async (payload) =>
    await service.listaCiudades(payload)
        .then(response => response);

function* fetchListaCiudades({ payload }) {
    try {
        const fetchData = yield call(fetchListaCitiesRequest, payload);
        yield put(ACTIONS.fetchListaCitiesSuccess(fetchData));
    } catch (error) {
        yield put(ACTIONS.fetchListaCitiesFailure(error));
    }
}

const fetchListaTalleresRequest = async (payload) =>
    await service.listaTalleres(payload)
        .then(response => response);

function* fetchListaTalleres({ payload }) {
    try {
        const fetchData = yield call(fetchListaTalleresRequest, payload);
        yield put(ACTIONS.fetchListaTalleresSuccess(fetchData));
    } catch (error) {
        yield put(ACTIONS.fetchListaTalleresFailure(error));
    }
}


const fetchDashboardRequest = async (payload) =>
    await service.getDashboard(payload)
        .then(response => response);

function* fetchDashboard({ payload }) {
    try {
        const fetchData = yield call(fetchDashboardRequest, payload);
        yield put(ACTIONS.fetchDashboardSuccess(fetchData));
    } catch (error) {
        yield put(ACTIONS.fetchDashboardFailure(error));
    }
}


const fetchDashboardDetailsRequest = async (payload) =>
    await service.getDashboardDetail(payload)
        .then(response => response);

function* fetchDashboardDetails({ payload }) {
    try {
        const fetchData = yield call(fetchDashboardDetailsRequest, payload);
        yield put(ACTIONS.fetchDashboardDetailsSuccess(fetchData));
    } catch (error) {
        yield put(ACTIONS.fetchDashboardDetailsFailure(error));
    }
}


const fetchExportCasosTallerRequest = async (payload) =>
    await service.exportarCasosTablero(payload)
        .then(response => response);

function* fetchExportCasosTaller({ payload }) {
    try {
        const fetchData = yield call(fetchExportCasosTallerRequest, payload);
        window.open(fetchData);
        yield put(ACTIONS.fetchExportCasosTallerSuccess(fetchData));
    } catch (error) {
        yield put(ACTIONS.fetchExportCasosTallerFailure(error));
    }
}

const fetchListaFilterRequest = async (payload) =>
    await service.consultarListaFiltro(payload)
        .then(response => response);

function* fetchListaFilter({ payload }) {
    try {
        const fetchData = yield call(fetchListaFilterRequest, payload);
        yield put(ACTIONS.fetchListaFilterSuccess(fetchData));
    } catch (error) {
        console.log(error);
        yield put(ACTIONS.fetchListaFilterFailure(error));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(ACTION_TYPES.FECTH_CITIES_REQUEST, fetchListaCiudades),
        takeEvery(ACTION_TYPES.FETCH_LISTA_TALLERES_REQUEST, fetchListaTalleres),
        takeEvery(ACTION_TYPES.FETCH_DASHBOARD_REQUEST, fetchDashboard),
        takeEvery(ACTION_TYPES.FETCH_DASHBOARD_DETAILS_REQUEST, fetchDashboardDetails),
        takeEvery(ACTION_TYPES.FETCH_EXPORT_CASOS_REQUEST, fetchExportCasosTaller),
        takeEvery(ACTION_TYPES.FETCH_LISTA_FILTER_REQUEST, fetchListaFilter),
    ]);
}