import {
    FETCH_LISTA_EVENTOS_SUCCESS,
    FETCH_LISTA_CASOS_SUCCESS,
    FETCH_LISTA_MARCAS_SUCCESS,
    FETCH_LISTA_COMPANIAS_SUCCESS,
    ADD_EVENT_SUCCESS,
    ADD_EVENT_FAILURE,
    ADD_EVENT_REQUEST,
    FECTH_EVENT_SUCCESS
} from 'constants/ActionTypes';

export const INIT_STATE = {
    listaEventos: [],
    listaCasos: [],
    listaMarcas: [],
    listaCompanias: [],
    loader: true,
    showMessage: false,
    message: "",
    type: "",
    titleMessage: "",
    eventAdded: false,
    eventos: [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_LISTA_EVENTOS_SUCCESS: {
            return {
                ...state,
                loader: false,
                listaEventos: action.payload
            }
        }
        case FETCH_LISTA_CASOS_SUCCESS: {
            return {
                ...state,
                loader: false,
                listaCasos: action.payload
            }
        }
        case FETCH_LISTA_MARCAS_SUCCESS: {
            return {
                ...state,
                loader: false,
                listaMarcas: action.payload
            }
        }
        case FETCH_LISTA_COMPANIAS_SUCCESS: {
            return {
                ...state,
                loader: false,
                listaCompanias: action.payload
            }
        }
        case ADD_EVENT_SUCCESS: {
            return {
                ...state,
                loader: false,
                showMessage: true,
                eventAdded: true,
                message: "El evento se ha registrado",
                type: "success",
                titleMessage: "Exito"
            }
        }
        case ADD_EVENT_REQUEST: {
            return {
                ...state,
                loader: false,
                showMessage: false,
                message: "",
                type: "",
                titleMessage: ""
            }
        }
        case ADD_EVENT_FAILURE: {
            return {
                ...state,
                loader: false,
                showMessage: true,
                message: action.payload.statusMessage,
                type: "danger",
                titleMessage: "Ha ocurrido un error",
                eventAdded: false
            }
        }
        case FECTH_EVENT_SUCCESS: {
            return {
                ...state,
                eventos: action.payload                
            }
        }
        default:
            return {
                ...state,
                loader: false,
                showMessage: false,
                message: "",
                type: "",
                titleMessage: "",
                eventAdded: false
            }
    }
}