export const loginUser = (request) => {
  const LOGIN_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/account/login";

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  };

  return fetch(LOGIN_API_ENDPOINT, parameters)
    .then(response => {
      return response.json();
    })
    .then(json => {
      return json;
    });
};

export const refreshToken = (request) => {
  const REFRESH_API_ENDPOINT = process.env.REACT_APP_API_URL + "/api/Token/refresh";

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  };

  return fetch(REFRESH_API_ENDPOINT, parameters)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(JSON.stringify({ code: response.status, statusMessage: response.statusText }));
      }
    })
    .then(json => {
      return json;
    });
};