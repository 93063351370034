import { all, call, fork, put, takeEvery, race, take } from "redux-saga/effects";
import { loginUser, refreshToken } from '../services/authService';
import {
    auth,
    facebookAuthProvider,
    githubAuthProvider,
    googleAuthProvider,
    twitterAuthProvider
} from "../firebase/firebase";
import {
    SIGNIN_FACEBOOK_USER,
    SIGNIN_GITHUB_USER,
    SIGNIN_GOOGLE_USER,
    SIGNIN_TWITTER_USER,
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER
} from "constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess } from "actions/Auth";
import {
    userFacebookSignInSuccess,
    userGithubSignInSuccess,
    userGoogleSignInSuccess,
    userTwitterSignInSuccess,
    userTokenRefreshRequest,
    userTokenRefreshSuccess,
    userTokenRefreshFailure
} from "../actions/Auth";

const createUserWithEmailPasswordRequest = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithPasswordRequest = async (payload) =>
    await loginUser(payload)
        .then(authUser => authUser)
        .catch(error => error);

const refreshTokenRequest = async (payload) =>
    await refreshToken(payload)
        .then(tokenResponse => tokenResponse);

const signOutRequest = async () =>
    await auth.signOut()
        .then(authUser => authUser)
        .catch(error => error);


const signInUserWithGoogleRequest = async () =>
    await auth.signInWithPopup(googleAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithFacebookRequest = async () =>
    await auth.signInWithPopup(facebookAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithGithubRequest = async () =>
    await auth.signInWithPopup(githubAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithTwitterRequest = async () =>
    await auth.signInWithPopup(twitterAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
    const { email, password } = payload;
    try {
        const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userSignUpSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signInUserWithGoogle() {
    try {
        const signUpUser = yield call(signInUserWithGoogleRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGoogleSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithFacebook() {
    try {
        const signUpUser = yield call(signInUserWithFacebookRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userFacebookSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithGithub() {
    try {
        const signUpUser = yield call(signInUserWithGithubRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGithubSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithTwitter() {
    try {
        const signUpUser = yield call(signInUserWithTwitterRequest);
        if (signUpUser.message) {
            if (signUpUser.message.length > 100) {
                yield put(showAuthMessage('Your request has been canceled.'));
            } else {
                yield put(showAuthMessage(signUpUser.message));
            }
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userTwitterSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signInUserWithEmailPassword({ payload }) {
    //const { email, password } = payload;
    if (!payload.username || payload.username === "") {
        yield put(showAuthMessage('Debe ingresar el nombre de usuario'));
    } else if (!payload.password || payload.password === "") {
        yield put(showAuthMessage('Debe ingresar la contraseña'));
    } else {
        try {
            const signInUser = yield call(signInUserWithPasswordRequest, payload);
            console.log(signInUser);
            if (signInUser.message) {
                yield put(showAuthMessage(signInUser.message));
            } else {
                localStorage.setItem('user_id', JSON.stringify(signInUser));
                yield put(userSignInSuccess(signInUser));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }

}


function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        if (signOutUser === undefined) {
            localStorage.removeItem('user_id');
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* createUserAccount() {
    yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
    yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
    yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
    yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
    yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

const ignoreActionTypes = ['TOKEN_REFRESH']

function monitorableAction(action) {
    console.log("monitoring..." +
        action.type);
    return action.type
        .includes('request') && ignoreActionTypes
            .every(fragment => !action.type.includes(fragment))
}

function identifyAction(action) {
    return action.type.split('_').slice(0, -1).join('_')
}
function getSuccessType(action) {
    return `${identifyAction(action)}_success`
}
function getFailType(action) {
    return `${identifyAction(action)}_failure`
}

function* monitor(monitoredAction) {
    console.log('started monitoring', monitoredAction.type)
    const { fail } = yield race({
        success: take(getSuccessType(monitoredAction)),
        fail: take(getFailType(monitoredAction)),
    })

    if (fail && fail.payload && (fail.payload.code === 401 || fail.payload.code === "401")) {
        console.log('detected 401, refreshing token')
        yield put(userTokenRefreshRequest())
        const userData = JSON.parse(localStorage.getItem('user_id'));
        try {
            const refreshTokenResponse = yield call(refreshTokenRequest, userData);
            userData.token = refreshTokenResponse.token;
            userData.refreshToken = refreshTokenResponse.refreshToken;
            localStorage.setItem('user_id', JSON.stringify(userData));
            yield put(userTokenRefreshSuccess());
            console.log('token refreshed, retrying', monitoredAction.type)
            yield put(monitoredAction)
        } catch (error) {
            yield put(userTokenRefreshFailure());
            console.log('token refresh failed, logging out user')
            localStorage.removeItem('user_id');
            yield put(userSignOutSuccess(signOutUser));
        }

    }

    console.log('monitoring', monitoredAction.type, 'finished')
}

export function* monitorear() {
    yield takeEvery(monitorableAction, monitor)
}

export default function* rootSaga() {
    yield all([fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(monitorear),
    fork(signOutUser)]);
}